const namespace = 'ugc'
const session = {
  getStorage() {
    return JSON.parse(window.localStorage.getItem(namespace) || '{}')
  },
  setItem(key: string, value: any) {
    const data = this.getStorage()
    data[key] = value
    window.localStorage.setItem(namespace, JSON.stringify(data))
  },
  getItem(key: string) {
    const data = this.getStorage()
    return data[key]
  },
  removeItem(key: string) {
    const data = this.getStorage()
    delete data[key]
    window.localStorage.setItem(namespace, JSON.stringify(data))
  },
  clear() {
    window.localStorage.removeItem(namespace)
  }
}

export default session
